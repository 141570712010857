import { RouteDTO, StopDTO } from '@/dto/RouteDto';

export type RoutesStopsObj = {
  [id: number]: StopDTO[];
};

export type StopObj = {
  ids: Set<number>;
  routesIds: Set<number>;
  name: string;
  latitude: number;
  longitude: number;
};

const stopsParser = (routes: RouteDTO[]) => {
  const stopsArr: StopObj[] = [];

  routes.forEach((route) => {
    route.stops.forEach((st) => {
      const { longitude, latitude } = st;
      let stop = stopsArr.find(
        (st) => st.longitude == longitude && st.latitude == latitude
      );
      if (!stop) {
        stop = {
          ids: new Set([st.id]),
          routesIds: new Set([route.id]),
          name: st.name,
          latitude,
          longitude,
        };
        stopsArr.push(stop);
      } else {
        stop.ids.add(st.id);
        stop.routesIds.add(route.id);
      }
    });
  });
  return stopsArr;
};

export default stopsParser;
