<template>
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="bus-online"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.8072 10.8076C7.88402 5.7308 16.1152 5.7308 21.192 10.8076C21.5825 11.1981 22.2157 11.1981 22.6062 10.8076C22.9967 10.4171 22.9967 9.78392 22.6062 9.3934C16.7483 3.53553 7.25085 3.53553 1.39299 9.3934C1.00247 9.78392 1.00247 10.4171 1.39299 10.8076C1.78352 11.1981 2.41668 11.1981 2.8072 10.8076ZM5.63563 13.636C9.15035 10.1213 14.8488 10.1213 18.3636 13.636C18.7541 14.0266 19.3872 14.0266 19.7778 13.636C20.1683 13.2455 20.1683 12.6123 19.7778 12.2218C15.482 7.92606 8.51719 7.92606 4.22142 12.2218C3.83089 12.6123 3.83089 13.2455 4.22142 13.636C4.61194 14.0266 5.24511 14.0266 5.63563 13.636ZM8.46406 16.4645C10.4167 14.5118 13.5825 14.5118 15.5351 16.4645C15.9257 16.855 16.5588 16.855 16.9493 16.4645C17.3399 16.0739 17.3399 15.4408 16.9493 15.0503C14.2157 12.3166 9.78351 12.3166 7.04984 15.0503C6.65932 15.4408 6.65932 16.0739 7.04984 16.4645C7.44037 16.855 8.07353 16.855 8.46406 16.4645ZM9.8781 17.8787C11.0497 16.7071 12.9492 16.7071 14.1207 17.8787C14.5113 18.2692 14.5113 18.9024 14.1207 19.2929L12.7065 20.7071C12.316 21.0976 11.6828 21.0976 11.2923 20.7071L9.8781 19.2929C9.48757 18.9024 9.48757 18.2692 9.8781 17.8787Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.bus-online {
  height: 25px;
  width: fit-content;
  fill: gray;
  opacity: 0.7;
  transition: opacity 0.3s;
  &:hover {
    opacity: 1;
  }
  &.active {
    fill: var(--color, black);
  }
}
</style>
