import { BusLocationDTO } from '@/dto/BusDto';
import { RouteDTO } from '@/dto/RouteDto';
import {
  parseBusLocationsData,
  parseRouteData,
} from '@/helpers/parsers';

export default class API {
  static API_URL: string = process.env.VUE_APP_API_URL;

  static async getRoutes(
    id?: number,
    settings?: RequestInit
  ): Promise<RouteDTO[]> {
    const routes =
      (await this.call<RouteDTO[]>(
        'routes/' + (id ? id : ''),
        settings
      )) || [];
    return routes.map(parseRouteData);
  }

  static async getBusesLocations(
    routeId?: number
  ): Promise<BusLocationDTO[]> {
    const buses =
      (await this.call<BusLocationDTO[]>(
        'bus-locations/' + (routeId ? routeId : '')
      )) || [];
    return buses.map(parseBusLocationsData);
  }

  static async call<T>(
    endPoint: string,
    settings?: RequestInit
  ): Promise<T | null> {
    try {
      const response = await fetch(this.API_URL + endPoint, settings);
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
      const { data } = await response.json();
      return data;
    } catch (error) {
      console.error(error); // log error to console
      return null;
    }
  }
}
