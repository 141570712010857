import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import VueTippy from 'vue-tippy';
import './styles/index.scss';
import 'tippy.js/dist/tippy.css';

const app = createApp(App);
app.use(router);
app.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  component: 'tippy', // => <tippy/>
});
app.mount('#app');
