import { StopObj } from '@/helpers/parseStops';
import { createSVGRing } from '@/helpers/createSVGRing';
import L from 'leaflet';
export default class Stop {
  info: StopObj;
  displayed = new Set<number>(); // displayed route ids
  marker: L.Marker | undefined;
  colors: string[] = [];
  iconSize: number = 24;

  constructor(stopData: StopObj) {
    this.info = stopData;
    this.setMarker();
  }

  async setMarker() {
    const coord = new L.LatLng(
      this.info.latitude,
      this.info.longitude
    );
    const icon = L.divIcon({
      html: createSVGRing(this.colors),
    });
    this.marker = new L.Marker(coord, {
      icon,
      title: `Зупинка: ${this.info.name}`,
    });
    const tooltip = new L.Tooltip({
      content: `Зупинка: ${this.info.name}`,
    });
    this.marker.on('click', (e: L.PopupEvent) => {
      const map = e.target._map;
      map.fire('openStopPopup', { type: 'stop', data: this.info });
    });
    this.marker.bindTooltip(tooltip);
  }
  updateIcon(size?: number) {
    const icon = L.divIcon({
      html: createSVGRing(this.colors),
      iconSize: [size || this.iconSize, size || this.iconSize],
      className: 'custom-marker stop-marker',
    });
    this.marker?.setIcon(icon);
  }

  addDisplayed(
    routeId?: number,
    color?: string,
    size?: number,
    num?: number
  ) {
    routeId ? this.displayed.add(routeId) : null;
    color ? this.colors.push(color) : null;
    this.updateIcon(size);
    if (num) {
      this.marker?.getElement()?.setAttribute('data-num', '' + num);
    }
  }
  deleteDisplayed(routeId?: number, color?: string) {
    routeId ? this.displayed.delete(routeId) : null;
    this.colors = this.colors.filter((c) => c !== color);
    if (this.displayed.size == 0) {
      this.marker?.remove();
      return;
    }
    this.updateIcon();
  }
}
