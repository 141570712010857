
import BusMap from "@/modules/BusMap";
import "leaflet/dist/leaflet.css";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { LightRouteDto, RouteDTO } from "@/dto/RouteDto";
import RouteSwitch from "./RouteSwitch.vue";
import StopPopup from "./StopPopup.vue";
import RoutePopup from "./RoutePopup.vue";
import RoutesDetails from "./RoutesDetails.vue";
import stopsParser, { StopObj } from "@/helpers/parseStops";
import { RoutePopupData, StopPopupData } from "@/types/popupsData";
import BusRoute from "@/modules/BusRoute";

export default defineComponent({
  name: "BusMap",
  components: {
    RouteSwitch,
    StopPopup,
    RoutePopup,
    RoutesDetails,
  },
  setup() {
    const map: Ref<null | BusMap> = ref(null);
    const stopPopupData: Ref<StopPopupData | null> = ref(null);
    const routePopupData: Ref<RoutePopupData | null> = ref(null);
    const routesDetailsData: Ref<BusRoute[] | null> = ref([]);
    const setStopPopup = (stop: StopObj, routes: LightRouteDto[]) => {
      if (routePopupData.value) {
        map.value?.hideRouteDetails(routePopupData.value.id);
      }
      routePopupData.value = null;
      stopPopupData.value = { routes, stop };
    };
    const setRoutePopup = (route: RoutePopupData) => {
      stopPopupData.value = null;
      routePopupData.value = route;
    };
    onMounted(() => {
      map.value = new BusMap("mapContainer");
      map.value.on("routes::set", (routes: BusRoute[]) => {
        routesDetailsData.value = routes;
      });
      map.value.setStopPopupCb(setStopPopup);
      map.value.setRoutePopupCb(setRoutePopup);
    });

    const routesHide = (ids: number[]) => {
      ids.forEach((id) => {
        map.value?.hideRoute(id);
      });
    };
    const displayRoutes = (routesObj: { [color: string]: RouteDTO }) => {
      Object.keys(routesObj).forEach((color) => {
        map.value?.displayRoute(routesObj[color], color);
      });
    };

    const setRoutes = (routes: RouteDTO[]) => {
      if (!map.value) {
        throw new Error("Map does not exist");
      }
      map.value.setRoutes(routes);
      const stops = stopsParser(routes);
      map.value.setStops(stops);
    };
    const closeRoutePopup = () => {
      if (!routePopupData.value) {
        throw new Error("Route popup data error");
      }
      map.value?.hideRouteDetails(routePopupData.value?.id);
      routePopupData.value = null;
    };
    const displayBuses = (routeId: number, isDisplayed: boolean) => {
      if (isDisplayed) {
        map.value?.hideBuses(routeId);
      } else {
        map.value?.displayBuses(routeId);
      }
    };

    return {
      routesHide,
      displayRoutes,
      stopPopupData,
      routePopupData,
      closeRoutePopup,
      setRoutes,
      routesDetailsData,
      displayBuses,
    };
  },
});
