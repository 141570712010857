<template>
  <router-view />
</template>

<style lang="scss">
@use "@/styles/mixins.scss" as m;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  padding: 0px;
  margin: 0px;
}
.leaflet-routing-container.leaflet-bar.leaflet-routing-container-hide.leaflet-control {
  display: none;
}
// .leaflet-control-zoom {
//   @include m.shadow;
// }
</style>
