export function getRandomColors(numColors: number) {
  const result: string[] = [];
  const goldenRatioConjugate = 0.618033988749895;

  for (let i = 0; i < numColors; i++) {
    let hue = Math.floor(Math.random() * 360);
    hue += (i * 360) / numColors;
    hue = hue % 360;

    let saturation = 70 + Math.random() * 10;
    let lightness = 35 + Math.random() * 10;

    result.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);

    saturation *= 0.9;
    lightness *= 1.1;
    hue += 180;
    hue = hue % 360;

    result.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
  }

  return result;
}
