export function createSVGRing(
  c: string[],
  centerColor: string = 'white'
): string {
  if (c.length < 1) {
    return '';
  }
  const colors = c.length > 1 ? c : [...c, ...c];
  const radius = 100;
  const circumference = 2 * Math.PI * radius;
  const segmentAngle = 360 / colors.length;
  const segmentLength = circumference / colors.length;

  let svg = `<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">`;

  colors.forEach((color, index) => {
    const startAngle = index * segmentAngle;
    const endAngle = startAngle + segmentAngle;
    const startAngleRad = (startAngle * Math.PI) / 180;
    const endAngleRad = (endAngle * Math.PI) / 180;

    const startX = 100 + radius * Math.sin(startAngleRad);
    const startY = 100 - radius * Math.cos(startAngleRad);
    const endX = 100 + radius * Math.sin(endAngleRad);
    const endY = 100 - radius * Math.cos(endAngleRad);

    const dasharray = `${segmentLength} ${
      circumference - segmentLength
        ? circumference - segmentLength
        : segmentLength
    }`;
    svg += `<path d="M 100,100 L ${startX},${startY} A ${radius},${radius} 0 ${
      segmentAngle > 180 ? 1 : 0
    },1 ${endX},${endY} Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-dasharray="${dasharray}" />`;
  });

  svg += `<circle cx="${radius}" cy="${radius}" r="${
    radius * 0.55
  }" fill="${centerColor}" stroke="white" stroke-width="2"/>`;
  svg += `</svg>`;
  return svg;
}
