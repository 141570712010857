type CallBack = (...args: any[]) => void;

interface EventHandler {
  [key: string]: CallBack[];
}

interface EventSubscriber {
  events: EventHandler;
  on(key: string, callBack: CallBack): void;
  trigger(key: string, ...args: any[]): void;
}

class EventSubscriberImpl implements EventSubscriber {
  events: EventHandler = {};

  on(key: string, callBack: CallBack): void {
    if (!this.events[key]) {
      this.events[key] = [];
    }
    this.events[key].push(callBack);
  }

  trigger(key: string, ...args: any[]): void {
    const callbacks = this.events[key];
    if (callbacks) {
      for (const callback of callbacks) {
        callback(...args);
      }
    }
  }
}
export default EventSubscriberImpl;
