import { RouteDTO, StopDTO } from '@/dto/RouteDto';
import L, { LatLng } from 'leaflet';
import { RouterSingleton } from './router';

export type BusRouteOptions = {
  color?: string;
  reverceColor?: string;
};

export default class BusRoute {
  id: number;
  number: string;
  name: string;
  stops: StopDTO[] = [];
  routes: {
    back?: L.Polyline;
    forward?: L.Polyline;
  } = {};
  waypoints: L.Routing.Waypoint[] = [];
  reverse: L.Routing.Waypoint[] = [];
  color?: string;
  displayBuses: boolean = false;
  middle: number | null;

  busStopIcon = L.icon({
    iconUrl: '/img/bus-stop.svg',
    iconSize: [25, 25],
  });

  constructor(routeData: RouteDTO) {
    const { stops, name, id, number } = routeData;
    this.name = name;
    this.id = id;
    this.number = number;
    this.stops = stops;
    this.middle = this.middlePoint(stops);
    this.parceStops(this.middle);
  }

  parceStops(middle: number | null) {
    this.stops.forEach((stop, index) => {
      const lat = stop.latitude;
      const lon = stop.longitude;
      if (!middle) {
        this.waypoints.push(
          new L.Routing.Waypoint(new LatLng(lat, lon), stop.name, {})
        );
        return;
      }
      if (middle < index) {
        stop.direction = 1;
        this.reverse.push(
          new L.Routing.Waypoint(new LatLng(lat, lon), stop.name, {})
        );
        return;
      }
      stop.direction = 2;
      this.waypoints.push(
        new L.Routing.Waypoint(new LatLng(lat, lon), stop.name, {})
      );
      if (middle == index) {
        this.reverse.push(
          new L.Routing.Waypoint(new LatLng(lat, lon), stop.name, {})
        );
        return;
      }
    });
  }

  middlePoint(stops: StopDTO[]) {
    const end = this.name.split(' - ')[1];
    if (!end) {
      return null;
    }
    let middle = stops.findIndex(
      (st) => st.name.trim() == end.trim()
    );
    if (middle == 0) {
      const end = this.name.split(' - ')[0].replace(/[0-9]/g, '');
      middle = stops.findIndex((st) => st.name.trim() == end.trim());
    }
    if (middle == -1) {
      middle = stops.findIndex((st) => st.name.includes(end.trim()));
    }
    return middle != -1
      ? middle
      : Number((stops.length / 2).toFixed(0));
  }

  async setLine(points: L.Routing.Waypoint[]) {
    const coords = await this.getPoints(points);
    let line;
    if (coords) {
      line = L.polyline(coords, {
        className: 'route-line',
      });
      const popup = new L.Popup({
        content: `<h3>Маршрут: №${this.name}</h3>`,
      });
      line.bindPopup(popup);
    }
    return line;
  }

  setListener(route?: L.Polyline) {
    if (!route) {
      throw new Error('Route display error');
    }
    const { id, name, number, stops } = this;
    const data = { id, name, number, stops };
    route.on('click', (e: L.PopupEvent) => {
      const map = e.target._map;
      map.fire('openRoutePopup', {
        data: { routeData: data, routeObj: this },
      });
    });
  }

  async setLines() {
    if (!this.routes.forward) {
      this.routes.forward = await this.setLine(this.waypoints);
      this.setListener(this.routes.forward);
    }
    if (!this.routes.back) {
      this.routes.back = await this.setLine(this.reverse);
      this.setListener(this.routes.back);
    }
    this.setLineColors();
  }

  setLineColors(color?: string) {
    if (color) {
      this.color = color;
    }
    if (this.routes?.back) {
      this.routes.back.options.color = color ? color : 'red';
    }
    if (this.routes.forward) {
      this.routes.forward.options.color = color ? color : 'blue';
    }
  }

  async getPoints(
    points: L.Routing.Waypoint[]
  ): Promise<LatLng[] | undefined> {
    const router: L.Routing.OSRMv1 = RouterSingleton.getRouter();
    try {
      const routes: L.Routing.IRoute[] = await new Promise<
        L.Routing.IRoute[]
      >((resolve, reject) => {
        router.route(
          points,
          (err: any, routes?: L.Routing.IRoute[]) => {
            if (err || !routes) {
              reject(err);
            } else {
              resolve(routes);
            }
          }
        );
      });
      const route = routes[0];
      return route.coordinates;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}
