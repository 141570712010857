import { BusLocationDTO } from '@/dto/BusDto';
import { RouteDTO, StopDTO } from '@/dto/RouteDto';
import validateCoords from './validateCoordinate';

export const parseRouteData = (route: RouteDTO): RouteDTO => {
  return {
    ...route,
    id: Number(route.id),
    stops: route.stops.map(parseStopData),
  };
};

export const parseStopData = (stop: StopDTO): StopDTO => {
  return {
    ...stop,
    id: Number(stop.id),
    number: Number(stop.number),
    longitude: validateCoords(stop.longitude),
    latitude: validateCoords(stop.latitude),
  };
};

export const parseBusLocationsData = (bus: BusLocationDTO) => {
  return {
    bus_id: Number(bus.bus_id),
    route_id: Number(bus.route_id),
    speed: Number(bus.speed),
    latitude: validateCoords(bus.latitude),
    longitude: validateCoords(bus.longitude),
    next_stop: Number(bus.next_stop),
    created_at: bus.created_at,
  };
};
