import { BusLocationDTO } from '@/dto/BusDto';
import L from 'leaflet';
import { BusSvg } from '@/helpers/icons/bus';
import { StopObj } from '@/helpers/parseStops';
import EventSubscriberImpl from '@/helpers/EventSubscriber';
import { RouterSingleton } from './router';

export default class Bus extends EventSubscriberImpl {
  busIcon = L.divIcon({
    html: BusSvg(),
    iconSize: [25, 25],
    className: 'custom-marker',
  });
  id: number;
  location: {
    coord: L.LatLng;
    time: Date;
  };
  nextStop?: StopObj;
  marker: L.Marker;

  constructor(location: BusLocationDTO, nextStop?: StopObj) {
    super();
    this.id = location.bus_id;
    this.location = {
      coord: new L.LatLng(location.latitude, location.longitude),
      time: new Date(location.created_at + 'GMT'),
    };
    this.marker = L.marker(this.location.coord, {
      icon: this.busIcon,
    });
    this.nextStop = nextStop;
    if (nextStop) {
      this.nextStop = nextStop;
      this.calcTime(
        new L.LatLng(nextStop.latitude, nextStop.longitude)
      );
    }
    this.on('timeCalculated', (arrivalTime) =>
      this.updatePopup(arrivalTime)
    );
  }

  setBusColor(color?: string) {
    this.busIcon = L.divIcon({
      html: BusSvg(color),
      iconSize: [25, 25],
      className: 'custom-marker',
    });
    this.marker.setIcon(this.busIcon);
  }

  async updatePopup(time: number) {
    this.marker.bindPopup(
      `Прибуде на зупинку <b> ${this.nextStop?.name} </b> через <b> ${time} хв</b>`
    );
  }

  calcTime(stopCoords: L.LatLng) {
    if (!this.nextStop) {
      return null;
    }
    const busWP = new L.Routing.Waypoint(
      L.latLng(this.location.coord.lat, this.location.coord.lng),
      'Bus',
      {}
    );
    const stopWP = new L.Routing.Waypoint(stopCoords, 'Stop', {});
    const router: L.Routing.OSRMv1 = RouterSingleton.getRouter();
    router.route([busWP, stopWP], (err: any, routes?: any[]) => {
      if (!routes) {
        return 
      }
      const summary = routes[0].summary;
      const arrivalTime = Math.round((summary.totalTime % 3600) / 60);
      this.trigger('timeCalculated', arrivalTime);
    });
  }

  updateBusData(location: BusLocationDTO, nextStop?: StopObj) {
    this.location = {
      coord: new L.LatLng(location.latitude, location.longitude),
      time: new Date(location.created_at + 'GMT'),
    };
    this.marker.setLatLng(this.location.coord);
    if (nextStop) {
      this.nextStop = nextStop;
      this.calcTime(
        new L.LatLng(nextStop.latitude, nextStop.longitude)
      );
    } else {
      this.marker.unbindPopup();
    }
  }
}
