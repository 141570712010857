import L from 'leaflet';
import 'leaflet-routing-machine';

export class RouterSingleton {
  static #instance?: L.Routing.OSRMv1;
  static getRouter() {
    if (!RouterSingleton.#instance) {
      RouterSingleton.#instance = L.Routing.mapbox(
        process.env.VUE_APP_MAPBOX_KEY,
        {}
      );
    }
    return RouterSingleton.#instance;
  }
}
