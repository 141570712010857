<template>
  <BusMap />
</template>

<script>
// @ is an alias to /src
import BusMap from "@/components/BusMap";

export default {
  name: "HomeView",
  components: {
    BusMap,
  },
};
</script>
