import { LightRouteDto, RouteDTO, StopDTO } from '@/dto/RouteDto';
import { StopObj } from '@/helpers/parseStops';
import { CustomLeafletEvent } from '@/types/popupEvent';
import { RoutePopupData } from '@/types/popupsData';
import L, { LatLng, Map } from 'leaflet';
import 'leaflet-routing-machine';
import API from './api';
import Bus from './Bus';
import BusRoute from './BusRoute';
import Stop from './Stop';
import EventSubscriberImpl from '@/helpers/EventSubscriber';

class BusMap extends EventSubscriberImpl {
  map: Map;
  routes: { [id: number]: BusRoute } = {};
  stops: Stop[] = [];
  buses: {
    [routeId: number]: Bus[];
  } = {};
  intervalId?: number;
  updateTime: number = 1000 * (process.env.VUE_APP_BUS_UPDATE_S || 10);
  activeRoutes: Set<number> = new Set();
  firstStopSize: number = 28;

  constructor(
    container: string,
    lat: number = 50.6059618,
    lng: number = 26.2780879
  ) {
    super();
    const center = new LatLng(lat, lng);
    const map = L.map(container, {
      zoomControl: true,
      fadeAnimation: true,
      markerZoomAnimation: true,
      zoomAnimation: false,
    }).setView(center, 14);
    const osm = L.tileLayer(
      'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      {}
    );
    map.zoomControl.setPosition('bottomleft');
    osm.addTo(map);
    map.on('popupopen', function (e) {
      map.panTo(e.target._popup._latlng, { animate: true }); // pan to new center
    });
    this.map = map;
    this.updateBusesData();
    this.subscribeToUpdateBuses();
  }

  setStopPopupCb(
    cb: (stop: StopObj, routes: LightRouteDto[]) => void
  ) {
    this.map.on('openStopPopup', (e: L.LeafletEvent) => {
      const customEvent = e as CustomLeafletEvent<StopObj>;
      const { routesIds } = customEvent.data;
      const routes = Object.values(this.routes)
        .filter((r) => routesIds.has(r.id))
        .map((r) => ({ id: r.id, name: r.name, color: r.color }));
      cb(customEvent.data, routes);
    });
  }
  setRoutePopupCb(cb: (route: RoutePopupData) => void) {
    this.map.on('openRoutePopup', (e: L.LeafletEvent) => {
      const customEvent = e as CustomLeafletEvent<{
        routeData: RoutePopupData;
        routeObj: BusRoute;
      }>;
      cb(customEvent.data.routeData);
      this.displayRouteDetails(customEvent.data.routeObj);
    });
  }

  async displayRoute(routeData: RouteDTO, color: string) {
    let route = this.routes[routeData.id];
    if (!route) {
      route = await this.createRoute(routeData);
      this.routes[route.id] = route;
    }
    if (!route?.routes.back || !route?.routes.forward) {
      await route.setLines();
    }
    if (route.displayBuses) {
      this.displayBuses(route.id);
    }
    route.stops.forEach((st) =>
      this.addStop(st).addDisplayed(route?.id, color)
    );
    if (!route.routes.back || !route.routes.forward) {
      throw new Error('Route not initialized');
    }
    route.setLineColors(color);
    route.routes.forward.addTo(this.map);
    route.routes.back.addTo(this.map);
    this.activeRoutes.add(route.id);
    this.map.hasLayer(route.routes.forward);
  }

  displayRouteDetails(route: BusRoute) {
    this.hideRoutes();
    route.setLineColors();
    route.routes.forward?.addTo(this.map);
    route.routes.back?.addTo(this.map);
    let stI = 1;
    route.stops.forEach((st, index) => {
      const color = st.direction == 1 ? 'red' : 'blue';
      const size =
        index === 0 ||
        index === route.stops.length - 1 ||
        index === route.middle
          ? this.firstStopSize
          : undefined;
      this.addStop(st).addDisplayed(route.id, color, size, stI);
      stI = index === route.middle ? 1 : stI + 1;
    });
    this.buses[route.id]?.forEach((bus) => {
      const stop = route.stops.find((st) =>
        bus.nextStop?.ids.has(st.id)
      );
      const color = stop?.direction == 1 ? 'red' : 'blue';
      this.displayBusColor(bus, color);
    });
  }
  hideRoutes() {
    this.activeRoutes.forEach((id) => {
      const route = this.routes[id];
      this.buses[id]?.forEach((bus) => bus.marker.remove());
      route.routes.back?.remove();
      route.routes.forward?.remove();
      this.hideRouteStops(route);
    });
  }
  displayHidedRoutes() {
    this.activeRoutes.forEach((id) => {
      const route = this.routes[id];
      route.routes.forward?.addTo(this.map);
      route.routes.back?.addTo(this.map);
      if (route.displayBuses) {
        this.displayBuses(id);
      }
      route.stops.forEach((st) =>
        this.addStop(st).addDisplayed(route.id, route.color)
      );
    });
  }
  hideRouteDetails(routeId: number) {
    const route = this.routes[routeId];
    if (!route) {
      throw new Error('Route Storage error');
    }
    this.hideRouteStops(route);
    this.buses[routeId]?.forEach((bus) => bus.marker.remove());
    this.displayHidedRoutes();
    route.routes.back?.remove();
    route.routes.forward?.remove();
    route.setLineColors(route.color);
    route.routes.forward?.addTo(this.map);
    route.routes.back?.addTo(this.map);
    if (route.displayBuses) {
      this.displayBuses(routeId);
    }
  }

  async createRoute(routeData: RouteDTO) {
    const route = new BusRoute(routeData);
    await route.setLines();
    return route;
  }

  subscribeToUpdateBuses() {
    this.intervalId = setInterval(
      () => this.updateBusesData(),
      this.updateTime
    );
  }

  unsubscribeFromUpdate() {
    clearInterval(this.intervalId);
  }

  /**TODO: Add logic to resolve server errors */
  async updateBusesData(routeId?: number) {
    const busLocations = await API.getBusesLocations(routeId);
    if (!busLocations) {
      throw new Error('Something went wrong with buses');
    }
    busLocations.forEach((busLocation) => {
      const { route_id, next_stop, bus_id } = busLocation;
      let nextStop: Stop | undefined;
      if (next_stop) {
        nextStop = this.stops.find((st) =>
          st.info.ids.has(next_stop)
        );
      }
      let buses = this.buses[route_id];
      if (!buses) {
        buses = [];
        this.buses[route_id] = [];
      }
      let bus = buses.find((b) => b.id == bus_id);
      if (!bus) {
        bus = new Bus(busLocation, nextStop?.info);
        this.buses[route_id].push(bus);
        return;
      }
      bus.updateBusData(busLocation, nextStop?.info);
    });
  }

  displayBuses(routeId: number) {
    this.routes[routeId].displayBuses = true;
    const color = this.routes[routeId].color;
    this.buses[routeId]?.forEach((bus) => {
      this.displayBusColor(bus, color);
    });
  }

  displayBusColor(bus: Bus, color?: string) {
    bus.setBusColor(color);
    bus.marker.addTo(this.map);
  }

  hideBuses(routeId: number) {
    this.routes[routeId].displayBuses = false;
    this.buses[routeId]?.forEach((bus) => bus.marker.remove());
  }

  setStops(stops: StopObj[]) {
    this.stops = stops.map((st) => new Stop(st));
  }
  setRoutes(routes: RouteDTO[]) {
    routes
      .map((r) => new BusRoute(r))
      .forEach((r) => {
        this.routes[r.id] = r;
      });
    this.trigger('routes::set', Object.values(this.routes));
  }

  addStop(stopData: StopDTO) {
    const { id } = stopData;
    const stop = this.stops.find((st) => st.info.ids.has(id));
    if (!stop) {
      throw new Error('Stops error');
    }
    if (stop.marker && !this.map.hasLayer(stop.marker)) {
      stop.marker.addTo(this.map);
    }
    return stop;
  }

  getStop(stopId: number) {
    const stop = this.stops.find((st) => st.info.ids.has(stopId));
    return stop;
  }

  hideRouteStops(route: BusRoute) {
    route.stops.forEach((st) => {
      const color =
        st.direction == 2
          ? route.routes.forward?.options.color
          : route.routes.back?.options.color;
      this.getStop(st.id)?.deleteDisplayed(route.id, color);
    });
  }

  hideRoute(id: number) {
    const route = this.routes[id];
    this.hideBuses(id);
    if (route) {
      this.hideRouteStops(route);
      route.routes.back?.remove();
      route.routes.forward?.remove();
      this.activeRoutes.delete(route.id);
      return;
    }
  }
}
export default BusMap;
