const validateCoords = (coord: string | number) => {
  if (!coord) {
    return 0;
  }
  if (typeof coord === 'number') {
    return coord;
  }
  let newCoord = coord;
  if (parseFloat(newCoord)) {
    return parseFloat(newCoord);
  }
  while (!+newCoord[0]) {
    newCoord = newCoord.slice(1);
  }
  while (!+newCoord[newCoord.length - 1]) {
    newCoord = newCoord.slice(0, -1);
  }
  return parseFloat(newCoord);
};
export default validateCoords;
